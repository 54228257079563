import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonInput, IonItem, IonLabel, IonList, IonModal, IonSelect, IonSelectOption, IonTextarea, IonTitle, IonToolbar } from "@ionic/react";
import { SetStateAction, useEffect, useState } from "react"
import { createNewCurriculum, updateCurriculum } from "./Curriculums";
import "./CurriculumDialog.css";
import { CurriculumTypeIdArea, CurriculumTypeIdMaterial, CurriculumTypeIdSubject, CurriculumTypeNameArea, CurriculumTypeNameMaterial, CurriculumTypeNameSubject } from "../Contract";

interface CurricullumProps {
    id: string
    name : string
    type: string
    parent: number
    desc: string

}

interface CurriculumDialogProps {
    curriculumDialog : boolean
    showCurriculumDialog : React.Dispatch<SetStateAction<boolean>>
    curriculums : any
    editCurriculum : CurricullumProps
}

interface Parent {
    id: number
    name: string
}

const CurriculumDialog: React.FC<CurriculumDialogProps> = ({ curriculumDialog, showCurriculumDialog, curriculums, editCurriculum }) => {
    var [result, setResult] = useState<boolean>(false);
    var [saveState, setSaveState] = useState<boolean>(true);

    var [curriculumId, setCurriculumId] = useState<string>();
    var [name, setName] = useState<string>();
    var [type, setType] = useState<string>("area");
    var [nonCategoryInput, showNonCategoryInput] = useState<boolean>(false);
    var [parent, setParent] = useState<number>();
    var [itemInput, showItemInput] = useState<boolean>(false);
    var [desc, setDesc] = useState<string>();
    var [availableParents, setAvailableParents] = useState<Parent[]>([]);

    useEffect(() => {
        if (type === "" || type === "area") {
            showNonCategoryInput(false);
        } else {
            showNonCategoryInput(true);
        }

        if (type === "material") {
            var parents : Parent[] = [];
            showItemInput(true);

            Object.values<any>(curriculums).map((area, _) => {
                Object.values<any>(area.items).map((subject, _) => {
                    var parent = {
                        "id": subject.id,
                        "name": subject.name + " (" + area.name + ")"
                    }
                    parents.push(parent);
                });
            });

            setAvailableParents(parents);
        } else {
            showItemInput(false);

            var parents : Parent[] = [];
            Object.values<any>(curriculums).map((area, _) => {
                var parent = {
                    "id": area.id,
                    "name": area.name
                }
                parents.push(parent);
            });
            setAvailableParents(parents);
        }

        if (curriculumId) {
            setParent(editCurriculum.parent);
        }
    }, [type]);

    useEffect(() => {
        if (!saveState) {
            setSaveState(true);
        }

        if (result) {
            showCurriculumDialog(false);
        }
    }, [result]);

    useEffect(() => {
        if (curriculumDialog) {
            if (editCurriculum.id) {
                setCurriculumId(editCurriculum.id);
                setName(editCurriculum.name);
                if (CurriculumTypeIdArea === +editCurriculum.type) {
                    setType(CurriculumTypeNameArea);
                } else if (CurriculumTypeIdSubject === +editCurriculum.type) {
                    setType(CurriculumTypeNameSubject);
                } else if (CurriculumTypeIdMaterial === +editCurriculum.type) {
                    setType(CurriculumTypeNameMaterial);
                }
                setDesc(editCurriculum.desc);
            } else {
                console.log("new curriculum");
                setCurriculumId("");
                setName("");
                setType("");
                setParent(0);
                setDesc("");
            }
        }
    }, [curriculumDialog]);

    function onSaveClick() {
        setResult(false);
        setSaveState(false);
        if (CurriculumTypeNameArea === type && name && name.length > 0) {
            if (curriculumId) {
                updateCurriculum(setResult, editCurriculum.id, name, CurriculumTypeIdArea);
            } else {
                createNewCurriculum(setResult, name, CurriculumTypeIdArea);
            }
        } else if (CurriculumTypeNameSubject === type && name && name.length > 0 && parent && parent > 0) {
            if (curriculumId) {
                updateCurriculum(setResult, editCurriculum.id, name, CurriculumTypeIdSubject, parent);
            } else {
                createNewCurriculum(setResult, name, CurriculumTypeIdSubject, parent);
            }
        } else if (CurriculumTypeNameMaterial === type && name && name.length > 0 && parent && parent > 0 && desc && desc.length > 0) {
            if (curriculumId) {
                updateCurriculum(setResult, editCurriculum.id, name, CurriculumTypeIdMaterial, parent, desc);
            } else {
                createNewCurriculum(setResult, name, CurriculumTypeIdMaterial, parent, desc);
            }
        } else {
            console.log("save failed, required to fill all input")
            console.log("name: " + name);
            console.log("type: " + type);
            console.log("parent: " + parent);
            setSaveState(true);
        }
    }

    return <IonModal isOpen={curriculumDialog} onDidDismiss={() => showCurriculumDialog(false)}>
        <IonHeader>
            <IonToolbar>
                <IonTitle>New Curriculum</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <IonList>
                <IonItem>
                    <IonLabel>Name:</IonLabel>
                    <IonInput type="text" placeholder="Name" value={name} onIonChange={ e => setName(e.detail.value!) }/>
                </IonItem>
                <IonItem>
                    <IonLabel>Type:</IonLabel>
                    <IonSelect placeholder="Select Type" value={type} onIonChange={ e => setType(e.detail.value)}>
                        <IonSelectOption value="area">Area</IonSelectOption>
                        <IonSelectOption value="subject">Subject</IonSelectOption>
                        <IonSelectOption value="material">Material</IonSelectOption>
                    </IonSelect>
                </IonItem>
                <IonItem hidden={!nonCategoryInput}>
                    <IonLabel>Area/Subject:</IonLabel>
                    <IonSelect placeholder="Select Type Area/Subject" value={parent?.toString()} onIonChange={ e => setParent(+e.detail.value)}>
                        {
                            availableParents.map((parent, _) => {
                                return <IonSelectOption key={parent.id} value={parent.id.toString()}>{parent.name}</IonSelectOption>
                            })
                        }
                    </IonSelect>
                </IonItem>
                <IonItem hidden={!itemInput}>
                    <IonLabel>Description:</IonLabel>
                    <IonTextarea value={desc} onIonChange={ e => setDesc(e.detail.value!) }></IonTextarea>
                </IonItem>
            </IonList>
        </IonContent>
        <IonFooter>
            <IonToolbar>
                <IonButtons slot="end">
                    <IonButton fill="solid" onClick={() => showCurriculumDialog(false)}>Cancel</IonButton>
                    <IonButton fill="solid" onClick={() => onSaveClick()} disabled={!saveState}>Save</IonButton>
                </IonButtons>
            </IonToolbar>
        </IonFooter>
    </IonModal>
}

export default CurriculumDialog;
