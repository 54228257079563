import { IonContent, IonItem, IonLabel, IonList, IonModal } from "@ionic/react";
import { SetStateAction, useEffect, useState } from "react";
import { getRoleName } from "../Contract";

interface SelectRoleDialogProps {
    showSelectRole : boolean
    dismisSelectRole : React.Dispatch<SetStateAction<boolean>> 
    setSelectedRole : React.Dispatch<SetStateAction<number>>
}

interface RoleItemProps {
    id: number;
    name: string;
}

const SelectRoleDialog: React.FC<SelectRoleDialogProps> = ({ showSelectRole, dismisSelectRole, setSelectedRole }) => {
    const [availableRoles,setAvailableRoles] = useState<RoleItemProps[]>([]);

    useEffect(() => {
        var roles = localStorage.getItem("roles");
        if (roles && roles !== "undefined") {
            var availableRole = JSON.parse(roles);
            var items : RoleItemProps[] = [];

            availableRole.forEach((element: number) => {
                var item : RoleItemProps = {
                    id: element,
                    name: getRoleName(element)
                }
                items.push(item);
            });

            setAvailableRoles(items);
        }
    }, []);

    useEffect(() => {
        if (availableRoles && availableRoles.length === 1) {
            onRoleSelected(availableRoles[0].id);
        }
    }, [availableRoles]);

    function onRoleSelected(id: number) {
        localStorage.setItem("selectedRole", id.toString());
        setSelectedRole(id);
        dismisSelectRole(false)
        // window.location.reload();
    }

    return <IonModal isOpen={showSelectRole} onDidDismiss={() => dismisSelectRole(false)}>
        <IonContent>
            <IonList>
                { availableRoles.map((item, key) => {
                    return <IonItem key={key} onClick={() => { onRoleSelected(item.id); }}>
                            <IonLabel>{item.name}</IonLabel>
                        </IonItem>
                })}
            </IonList>
        </IonContent>
    </IonModal>;
}

export default SelectRoleDialog;