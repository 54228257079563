import { folderOutline, homeOutline, notificationsOffOutline, peopleOutline, settingsOutline, ticketOutline } from "ionicons/icons"
import { DefaultPage } from "../Contract"

export interface MenuItem {
    roleLevel: number,
    icon: string,
    title: string,
    url: string
}

export interface MenuCategory {
    roleLevel: number,
    icon: string,
    title: string,
    itemList: MenuItem[]
}

export const MenuList : MenuCategory[] = [
    {
        roleLevel: 100,
        icon: folderOutline,
        title: 'General',
        itemList: [
            {
                roleLevel: 100,
                icon: homeOutline,
                title: 'Home',
                url: DefaultPage
            },
            {
                roleLevel: 100,
                icon: peopleOutline,
                title: 'Parents',
                url: '/page/Parents'
            },
            {
                roleLevel: 100,
                icon: peopleOutline,
                title: 'Students',
                url: '/page/Students'
            }
        ]
    },
    {
        roleLevel: 30,
        icon: folderOutline,
        title: 'Manage',
        itemList: [
            {
                roleLevel: 30,
                icon: ticketOutline,
                title: 'Curriculums',
                url: '/page/Curriculums'
            },
            {
                roleLevel: 30,
                icon: peopleOutline,
                title: 'Peoples',
                url: '/page/Peoples'
            }
        ]
    },
    {
        roleLevel: 100,
        icon: settingsOutline,
        title: 'Settings',
        itemList: [
            {
                roleLevel: 30,
                icon: notificationsOffOutline,
                title: 'Notification',
                url: '/page/Notification'
            }
        ]
    },
]