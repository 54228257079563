import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonRow } from "@ionic/react";
import { LocalStorageParentDetail, LocalStorageStudentDetail, getGenderName, RoleCodeParent } from "../../Contract";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { searchLearnings } from "../../components/learnings/Learnings";
import LearningInfoDialog from "../../components/learnings/LearningInfoDialog";
import { addCircle } from "ionicons/icons";
import LearningAssignmentDialog from "../../components/learnings/LearningAssignmentDialog";
import { LoadingProps } from "../../App";
import LearningUpdateDialog from "../../components/learnings/LearningUpdateDialog";

const StudentDetailPage: React.FC<LoadingProps> = (loadingProps) => {
    const role = localStorage.getItem("selectedRole");

    const student = JSON.parse(localStorage.getItem(LocalStorageStudentDetail)!);
    const [learningList, setLearningList] = useState<any>(JSON.parse("{}"));
    const [selectLearningItem, setSelectLearningItem] = useState<any>(JSON.parse("{}"));
    const [showLearningInfoDialog, setShowLearningInfoDialog] = useState<boolean>(false);
    const [showlearningAssignmentDialog, setShowLearningAssignmentDialog] = useState<boolean>(false);
    const [showLearningUpdateDialog, setShowLearningUpdateDialog] = useState<boolean>(false);
    const history = useHistory();

    if (!student) {
        window.location.replace("/");
    }

    function calculateAge(birthdate: string): string {
        const birth = new Date(birthdate);
        const today = new Date();
        const diff = new Date(today.getTime() - birth.getTime());

        return (diff.getFullYear() - 1970) + " tahun " + diff.getMonth() + " bulan " + diff.getDay() + " hari";
    }

    function onParentSelected(content: any) {
        localStorage.setItem(LocalStorageParentDetail, JSON.stringify(content));
        history.push("/page/Parent");
    }

    function onLearningClick(learningItem : any) {
        setSelectLearningItem(learningItem);
        setShowLearningInfoDialog(true);
    }

    function onLearningAssignmentClick() {
        console.log("Learning Assingment Clicked");
        setShowLearningAssignmentDialog(true);
    }

    function onLearningEditClick(learningItem: any) {
        setSelectLearningItem(learningItem);
        setShowLearningUpdateDialog(true);
    }

    function isReadOnly() {
        if (role) {
            return +role === RoleCodeParent;
        } else {
            return false;
        }
    }

    useEffect(() => {
        if (!showlearningAssignmentDialog && !showLearningUpdateDialog) {
            searchLearnings(student.id, setLearningList, loadingProps.loadingShow);
        }
    }, [showlearningAssignmentDialog, showLearningUpdateDialog, student.id, loadingProps.loadingShow]);

    return <>
    <LearningInfoDialog learningItem={selectLearningItem} isShowDialog={showLearningInfoDialog} setShowDialog={setShowLearningInfoDialog} />
    <LearningAssignmentDialog student={student} isShowDialog={showlearningAssignmentDialog} loadingProps={loadingProps} setShowDialog={setShowLearningAssignmentDialog} />
    <LearningUpdateDialog learningItem={selectLearningItem} isShowDialog={showLearningUpdateDialog} setShowDialog={setShowLearningUpdateDialog} />
    
    <IonList>
    <IonCard>
        <IonCardHeader>
            <IonCardTitle>{student.name}</IonCardTitle>
            <IonCardSubtitle>{calculateAge(student.birthdate)}</IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
            <IonItem>
                <IonLabel>Gender: {getGenderName(student.gender)}</IonLabel>
                <IonLabel>Birthdate: {student.birthdate}</IonLabel>
                <IonLabel onClick={() => onParentSelected({ "email": student.parent, "name": student.parentName })}>Parent: {student.parentName}</IonLabel>
            </IonItem>
        </IonCardContent>
    </IonCard>
        <IonListHeader>
            <IonLabel>Curriculums</IonLabel>
            <IonButton size="small" onClick={() => onLearningAssignmentClick()} disabled={ isReadOnly() }><IonIcon slot="start" icon={addCircle} />Add</IonButton>
        </IonListHeader>
        <IonGrid><IonRow>
        {
            Object.values<any>(learningList).map((learning, index) => {
                return <IonCol size="12" size-sm="6" key={"learning." + index}><IonCard>
                        <IonCardHeader>
                        <IonCardTitle>{learning.curriculumName}</IonCardTitle>
                        <IonCardSubtitle>{learning.curriculumArea} - {learning.curriculumSubject}</IonCardSubtitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="auto">Date:</IonCol>
                                <IonCol>{learning.time}</IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="auto">Teacher:</IonCol>
                                <IonCol>{learning.teacherName}</IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                    <IonButton fill="clear" onClick={() => onLearningClick(learning)}>Detail</IonButton>
                    <IonButton fill="clear" onClick={() => onLearningEditClick(learning)} disabled={ isReadOnly() }>Edit</IonButton>
                    <IonButton fill="clear" disabled={ isReadOnly() }>Delete</IonButton>
                </IonCard></IonCol>
            })
        }
        </IonRow></IonGrid>
    </IonList>
    </>
}

export default StudentDetailPage;