import { IonContent, IonFooter, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';

import './Login.css';
import { ServerHost } from '../Contract';
import { LoadingProps } from '../App';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';

const Login: React.FC<LoadingProps> = (loadingProps) => {
    localStorage.clear();

    const onSignInButtonClicked = useGoogleLogin({
        flow: 'auth-code',
        onSuccess: async loginResponse => {
            console.log("login response", loginResponse);
            createClientSecret(loginResponse.code);
        }
    });

    function createClientSecret(authCode : string) {
        var request = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                "id": Math.random().toString().substring(2, 4),
                "type": "otp.create",
                "body": {
                    "type": 3,
                    "length": 6
                }
            })
        }

        fetch(ServerHost, request).then(async response => {
            if (response.ok) {
                var payload = await response.json();
                var body = payload.body;

                console.log("OTP creation success");
                
                createSession(authCode, body.id, body.secret);
            } else {
                console.log("failed to create OTP, received response: " + response.status);
                hideLoadingProgress();
            }
        }).catch((reason: any) => {
            console.log("failed to create OTP", reason);
            hideLoadingProgress();
        })
    }

    function createSession(authCode : string, otpId : string, otpSecret : string) {
        var redirectUrl = window.location.protocol + '//' + window.location.hostname;
        if (window.location.port !== "") {
            redirectUrl = redirectUrl + ':' + window.location.port;
        }
        console.log("redirect url => " + redirectUrl);

        var request = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                "id": Math.random().toString().substring(2, 4),
                "type": "rona.app.authentication",
                "body": {
                    "otpId": otpId,
                    "authCode": authCode,
                    "redirectUrl": redirectUrl
                }
            })
        }

        fetch(ServerHost, request).then(async response => {
            if (response.ok) {
                var payload = await response.json();
                var body = payload.body;

                if (payload.type === "rona.app.authentication") {
                    console.log("Session creation success");
                    saveCredentials(body.name, body.email, otpSecret, body.session, JSON.stringify(body.availableRoles));
                    hideLoadingProgress();
                    window.location.reload();
                } else {
                    console.log("failed to create session, received type: " + payload.type);
                    hideLoadingProgress();
                    showToast("Access denied");
                }
            } else {
                console.log("failed to create session, received response: " + response.status);
                hideLoadingProgress();
            }
        }).catch((reason: any) => {
            console.log("failed to create session", reason);
            hideLoadingProgress();
        })
    }

    function saveCredentials(name: string, email : string, secret : string, session : string, roles : string) {
        localStorage.setItem("name", name);
        localStorage.setItem("email", email);
        localStorage.setItem("secret", secret);
        localStorage.setItem("session", session);
        localStorage.setItem("roles", roles);

        console.log("name = " + name);
    }

    function hideLoadingProgress() {
        loadingProps.loadingMessage("");
        loadingProps.loadingShow(false);
    }

    function showToast(message: string) {
        loadingProps.toastMessage(message);
        loadingProps.toastShow(true);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Rona Montessori</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Rona Montessori</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent class="login-container-parent">
                    <div className="login-container">
                        <img src="/assets/background.jpg" alt="Rona Montessori" />
                    </div>
                    <div className="login-info">
                        Rona Montessori Web Application for <b>registered</b> employees (teacher) and parents to manage students curriculums, their progress, activities, and reports.
                        While using this applications, you agree to have read and accepted our <a href='/privacy.html' target={'_blank'}>privacy policy</a>.
                    </div>
                </IonContent>
            </IonContent>
            <IonFooter id="login-footer">
                <img src="/assets/btn_google_signin.png" alt="Sign in with Google" onClick={() => onSignInButtonClicked()} />
            </IonFooter>
        </IonPage>
    );
}

export default Login;