import {
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import { logOutOutline } from 'ionicons/icons';
import { googleLogout } from '@react-oauth/google';
import './Menu.css';
import { useEffect, useState } from 'react';
import { getRoleName } from '../Contract';
import SelectRoleDialog from './SelectRoleDialog';
import { MenuList } from './MenuData';
import { checkSession, exitSession } from './Sessions';

const Menu: React.FC = () => {
  const location = useLocation();

  async function onSignOutButtonClicked() {
    try {
      await exitSession();
      googleLogout();
    } finally {
      localStorage.clear();
      window.location.replace('/');
    }
  }

  const [name,setName] = useState<string>("");
  const [roleId, setRoleId] = useState<number>(-1);
  const [roleName, setRoleName] = useState<string>("");
  const [roleSelector, showRoleSelector] = useState<boolean>(false);
  const [sessionValid, setSessionValid] = useState<boolean>(true);

  useEffect(() => {
    var name = localStorage.getItem("name");
    var role = localStorage.getItem("selectedRole");
    
    if (name) {
      setName(name);
    }

    if (role) {
      setRoleId(+role);
    } else {
      showRoleSelector(true);
    }

  }, []);

  useEffect(() => {
    setRoleName(getRoleName(roleId));
    if (roleId > 0) {
      checkSession(setSessionValid);
    }
  }, [roleId]);

  useEffect(()=> {
    if (!sessionValid) {
      onSignOutButtonClicked();
    }
  }, [sessionValid]);

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <SelectRoleDialog key="selectRoleDialog" showSelectRole={roleSelector} dismisSelectRole={showRoleSelector} setSelectedRole={setRoleId} />
          <IonListHeader>{name}</IonListHeader>
          <IonNote onClick={() => { showRoleSelector(true) }}>{roleName}</IonNote>
          {MenuList.map((MenuCategory, index) => {
            if (roleId <= MenuCategory.roleLevel) {
              return <IonItemGroup key={index}>
                <IonItemDivider key={index}>
                  <IonIcon slot="start" icon={MenuCategory.icon} />
                  <IonLabel>{MenuCategory.title}</IonLabel>
                </IonItemDivider>
                {MenuCategory.itemList.map((MenuItem, index) => {
                  if (roleId <= MenuItem.roleLevel) {
                    return <IonMenuToggle key={index} autoHide={false}>
                      <IonItem className={location.pathname === MenuItem.url ? 'selected' : ''} routerLink={MenuItem.url} routerDirection="none" lines="none" detail={false}>
                        <IonIcon slot="start" icon={MenuItem.icon} />
                        <IonLabel>{MenuItem.title}</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                  } else {
                    return "";
                  }
                })}
              </IonItemGroup>
            } else {
              return "";
            }
          })}
        </IonList>
        <IonFooter>
          <IonItem button onClick={() => setSessionValid(false)}>
            <IonIcon slot="start" icon={logOutOutline} />
            <IonLabel>Sign Out</IonLabel>
          </IonItem>
        </IonFooter>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
