import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonInput, IonItem, IonLabel, IonList, IonModal, IonSelect, IonSelectOption, IonTitle, IonToolbar } from "@ionic/react"
import { SetStateAction, useEffect, useState } from "react"
import { retrieveCurriculums } from "../Curriculums"
import { getTeachers } from "../peoples/GetPersons"
import { JSONEmpty } from "../../Contract"
import { LoadingProps } from "../../App"
import { assignLearning } from "./Learnings"

interface LearningAssignmentDialogProps {
    isShowDialog: boolean,
    setShowDialog: React.Dispatch<SetStateAction<boolean>>
    loadingProps: LoadingProps
    student: any
}

interface CurriculumItem {
    id: number,
    parent: number,
    name: string,
    type: number,
    desc: string,
    items: Object
}

const LearningAssignmentDialog: React.FC<LearningAssignmentDialogProps> = ({ isShowDialog, setShowDialog, loadingProps, student }) => {
    const [curriculums, setCurriculums] = useState<any>(JSONEmpty);
    const [teachers, setTeachers] = useState<any>(JSONEmpty);

    const [listArea, setListArea] = useState<CurriculumItem[]>([]);
    const [listSubject, setListSubject] = useState<CurriculumItem[]>([]);
    const [listMaterial, setListMaterial] = useState<CurriculumItem[]>([]);

    const [selectedArea, setSelectedArea] = useState<CurriculumItem>();
    const [selectedSubject, setSelectedSubject] = useState<CurriculumItem>();
    const [selectedMaterial, setSelectedMaterial] = useState<CurriculumItem>();
    const [selectedTeacher, setSelectedTeacher] = useState<string>();
    const [selectedDate, setSelectedDate] = useState<string>();

    const [saveCallback, setSaveCallback] = useState<boolean>(false);
    var initial = true;

    useEffect(() => {
        if (isShowDialog) {
            setSaveCallback(false);
            loadingProps.loadingShow(true);
            getTeachers(0, setTeachers);
            retrieveCurriculums(setCurriculums, loadingProps.loadingShow);
        }
    }, [isShowDialog]);

    useEffect(() => {
        var areas: CurriculumItem[] = [];
        Object.values<any>(curriculums).map((area, _) => {
            return areas.push(area);
        });

        setListArea(areas);
    }, [curriculums]);

    useEffect(() => {
        if (selectedArea && selectedArea.items) {
            var subjects: CurriculumItem[] = [];
            Object.values<any>(selectedArea.items).map((subject, _) => {
                return subjects.push(subject);
            });

            setListSubject(subjects);
        }
    }, [selectedArea]);

    useEffect(() => {
        if (selectedSubject && selectedSubject.items) {
            var materials: CurriculumItem[] = [];
            Object.values<any>(selectedSubject.items).map((material, _) => {
                return materials.push(material);
            });

            setListMaterial(materials);
        }
    }, [selectedSubject]);

    useEffect(() => {
        if (saveCallback) {
            loadingProps.toastMessage("Learning assignment success");
            loadingProps.toastShow(true);
            setShowDialog(false);
        } else if (!initial) {
            loadingProps.toastMessage("Learning assignment fail");
            loadingProps.toastShow(true);
        }

        initial = false;
    }, [saveCallback])

    function submit() {
        if (selectedMaterial && selectedTeacher && selectedDate) {
            assignLearning(student.id, selectedMaterial.id, selectedDate, selectedTeacher, setSaveCallback);
        }
    }

    return <IonModal isOpen={isShowDialog} onDidDismiss={() => { setShowDialog(false) }}>
        <IonHeader>
            <IonToolbar>
                <IonTitle>Assign Curriculum for {student.name}</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <IonList>
                <IonItem>
                    <IonLabel>Curriculum:</IonLabel>
                    <IonSelect placeholder="Area" value={selectedArea} onIonChange={e => setSelectedArea(e.detail.value)}>
                        {
                            listArea.map((area, _) => {
                                return <IonSelectOption key={area.id} value={area}>{area.name}</IonSelectOption>
                            })
                        }
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonLabel/>
                    <IonSelect placeholder="Subject" value={selectedSubject} onIonChange={e => setSelectedSubject(e.detail.value)}>
                        {
                            listSubject!.map((subject, _) => {
                                return <IonSelectOption key={subject.id} value={subject}>{subject.name}</IonSelectOption>
                            })
                        }
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonLabel/>
                    <IonSelect placeholder="Material" value={selectedMaterial} onIonChange={e => setSelectedMaterial(e.detail.value)}>
                        {
                            listMaterial!.map((material, _) => {
                                return <IonSelectOption key={material.id} value={material}>{material.name}</IonSelectOption>
                            })
                        }
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonLabel>Teacher:</IonLabel>
                    <IonSelect placeholder="Select teacher" value={selectedTeacher?.toString()} onIonChange={e => setSelectedTeacher(e.detail.value)}>
                        {
                            Object.values<any>(teachers).map((teacher, _) => {
                                return <IonSelectOption key={teacher.email} value={teacher.email.toString()}>{teacher.name}</IonSelectOption>
                            })
                        }
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonLabel>Date:</IonLabel>
                    <IonInput type="date" value={selectedDate} onIonChange={ e => setSelectedDate(e.detail.value!) }/>
                </IonItem>
            </IonList>
        </IonContent>
        <IonFooter>
            <IonToolbar>
                <IonButtons slot="end">
                    <IonButton fill="solid" onClick={ () => submit() }>Save</IonButton>
                    <IonButton fill="solid" onClick={ () => setShowDialog(false) }>Cancel</IonButton>
                </IonButtons>
            </IonToolbar>
        </IonFooter>
    </IonModal>
}

export default LearningAssignmentDialog;
