import { SetStateAction } from "react";
import { createBasicRequestBody, UploadServerHost } from "../../Contract";

export async function uploadFile(id : string, file : File) : Promise<string|null> {
    // loadingShow(true);

    const formData = new FormData();
    formData.append("file", file);

    const accessTokenRaw = createBasicRequestBody({ "student": id });
    const accessTokenJson = JSON.stringify(accessTokenRaw);
    const accessToken = btoa(accessTokenJson);

    const response = await fetch(UploadServerHost, {
        method: "POST",
        headers: {
            "X-Access-Token": accessToken
        },
        body: formData
    });

    if (response && response.ok) {
        var payload = await response.text();
        return payload;
    } else {
        return null;
    }
}
