import { IonCol, IonFab, IonFabButton, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { personAddOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import AddPersonsDialog from "../../components/peoples/AddPersonsDialog";
import { getParents } from "../../components/peoples/GetPersons";
import './Teachers.css';
import { LocalStorageParentDetail, RoleCodeParent } from "../../Contract";
import { useHistory } from "react-router";
import { LoadingProps } from "../../App";

const Parents: React.FC<LoadingProps> = (loadingProps) => {
    const role = localStorage.getItem("selectedRole");
    const [personDialog,showPersonDialog] = useState<boolean>(false);
    const [parentList,setParentList] = useState<any>(JSON.parse("{}"));
    const [page,setPage] = useState<number>(0);
    const history = useHistory();

    useEffect(() => {
        if (role && RoleCodeParent === +role) {
            onParentSelected({
                "name": localStorage.getItem("name"),
                "email": localStorage.getItem("email")
            });
        } else {
            getParents(page, setParentList);
        }
    }, [personDialog, page]);

    function onParentSelected(content : any) {
        localStorage.setItem(LocalStorageParentDetail, JSON.stringify(content));
        history.push("/page/Parent");
    }

    return <>
        <AddPersonsDialog loadingProps={loadingProps} type={2} isDialogShown={personDialog} showDialog={showPersonDialog} passEmail="" /> 
        <IonFab vertical="bottom" horizontal="end" edge={false} slot="fixed">
            <IonFabButton id="fab-curriculums" onClick={() => {showPersonDialog(true)}}>
                <IonIcon icon={personAddOutline}/>
            </IonFabButton>
        </IonFab>
        <IonGrid>
            <IonRow key={"parent.header"}>
                <IonCol class="person-grid-header">Name</IonCol>
                <IonCol class="person-grid-header">E-Mail</IonCol>
            </IonRow>
            {
                Object.values<any>(parentList).map((parent, index) => {
                    return <IonRow key={"parent." + index} onClick={() => onParentSelected(parent)}>
                        <IonCol class="person-grid-item">{parent.name}</IonCol>
                        <IonCol class="person-grid-item">{parent.email}</IonCol>
                    </IonRow>
                })
            }
            <IonCol>
            </IonCol>
        </IonGrid>
    </>
}

export default Parents;