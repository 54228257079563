import { IonCol, IonFab, IonFabButton, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { personAddOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import AddPersonsDialog from "../../components/peoples/AddPersonsDialog";
import { getStudents } from "../../components/peoples/GetPersons";
import { LocalStorageParentDetail, LocalStorageStudentDetail, getGenderName } from "../../Contract";
import { useHistory } from "react-router";
import { LoadingProps } from "../../App";

interface StudentPageProps extends LoadingProps {
    isManager: boolean
}

const Students: React.FC<StudentPageProps> = (props) => {
    const [personDialog,showPersonDialog] = useState<boolean>(false);
    const [studentList,setStudentList] = useState<any>(JSON.parse("{}"));
    const [page,setPage] = useState<number>(0);
    const history = useHistory();

    useEffect(() => {
        getStudents(page, setStudentList);
    }, [personDialog, page]);

    function onStudentSelected(content : any) {
        onPeopleSelected(content, LocalStorageStudentDetail, "/page/Student");
    }
    
    function onParentSelected(content : any) {
        onPeopleSelected(content, LocalStorageParentDetail, "/page/Parent");
    }
    
    function onPeopleSelected(content : any, key : string, url : string) {
        localStorage.setItem(key, JSON.stringify(content));
        history.push(url);
    }
    
    return <>
        <AddPersonsDialog loadingProps={props} type={3} isDialogShown={personDialog} showDialog={showPersonDialog} passEmail=""/> 
        <IonFab vertical="bottom" horizontal="end" edge={false} slot="fixed">
            <IonFabButton hidden={!props.isManager} id="fab-curriculums" onClick={() => {showPersonDialog(true)}}>
                <IonIcon icon={personAddOutline}/>
            </IonFabButton>
        </IonFab>
        <IonGrid>
            <IonRow key={"student.header"}>
                <IonCol class="person-grid-header">Name</IonCol>
                <IonCol class="person-grid-header">Birthdate</IonCol>
                <IonCol class="person-grid-header">Gender</IonCol>
                <IonCol class="person-grid-header">Parent</IonCol>
            </IonRow>
            {
                Object.values<any>(studentList).map((student, index) => {
                    return <IonRow key={"student." + index}>
                        <IonCol class="person-grid-item" onClick={() => onStudentSelected(student)}>{student.name}</IonCol>
                        <IonCol class="person-grid-item">{student.birthdate}</IonCol>
                        <IonCol class="person-grid-item">{ getGenderName(student.gender) }</IonCol>
                        <IonCol class="person-grid-item"
                            onClick={() => onParentSelected({ "email": student.parent, "name": student.parentName })}>
                                {student.parentName}
                        </IonCol>
                    </IonRow>
                })
            }
            <IonCol>
            </IonCol>
        </IonGrid>
    </>
}

export default Students;