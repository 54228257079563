import { generateHmac } from "../SessionUtils";
import { ServerHost } from '../../Contract';
import React from "react";

export function getTeachers(page : number, setResult : React.Dispatch<any>) {
    getPerson("rona.app.teacher.get", page, "", setResult);
}

export function getParents(page : number, setResult : React.Dispatch<any>) {
    getPerson("rona.app.parent.get", page, "", setResult);
}

export function getParentDetail(parent : string, setResult : React.Dispatch<any>) {
    getPerson("rona.app.parent.detail.get", 0, parent, setResult);
}

export function getStudents(page: number, setResult : React.Dispatch<any>) {
    getPerson("rona.app.student.get", page, "", setResult);
}

export function getStudentsByParent(parent: string, setResult : React.Dispatch<any>) {
    getPerson("rona.app.student.get", 0, parent, setResult);
}

function getPerson(type : string, page : number, parent : string, setResult : React.Dispatch<any>) {
    var email = getCredential("email");
    var role = getCredential("selectedRole");
    var session = getCredential("session");
    var secret = getCredential("secret");

    var timestamp = Date.now().toString();
    var hmac = generateHmac(secret, timestamp, session);

    var body = {
        "email": email,
        "role": +role,
        "timestamp": timestamp,
        "secret": hmac,
    }

    if (parent && parent !== "") {
        Object.assign(body, { "parent": parent});
    }

    var request = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            "id": Math.random().toString().substring(2, 4),
            "type": type,
            "body": body
        })
    }

    fetch(ServerHost, request).then(async response => {
        if (response.ok) {
            var payload = await response.json();
            var body = payload.body;

            if (type === payload.type && body) {
                if (body instanceof Object) {
                    body = JSON.stringify(body);
                }
                console.log("Received person: " + body);
                setResult(JSON.parse(body));
            } else {
                console.log("Error in retrieving person, received status: " + payload.type);
            }
        } else {
            console.log("failed to retrieve person, received response: " + response.status);
        }
    }).catch((reason: any) => {
        console.log("Error to retrieve person", reason);
    })
}

function getCredential(key : string) : string {
    var value = localStorage.getItem(key);
    if (!value) {
        value = "";
    }
    return value;
}