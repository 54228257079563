import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonInput, IonItem, IonLabel, IonList, IonModal, IonTextarea, IonTitle, IonToolbar } from "@ionic/react"
import { SetStateAction } from "react"
import { renderMarkdown } from "./Learnings"

interface LearningInfoDialogProps {
    learningItem: any
    isShowDialog: boolean
    setShowDialog: React.Dispatch<SetStateAction<boolean>>
}

const LearningInfoDialog: React.FC<LearningInfoDialogProps> = ({ learningItem, isShowDialog, setShowDialog}) => {
    return <IonModal isOpen={isShowDialog} onDidDismiss={() => { setShowDialog(false) }}>
         <IonHeader>
            <IonToolbar>
                <IonTitle>{learningItem.curriculumName}</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <IonList>
                <IonItem>
                    <IonLabel>Area:</IonLabel>
                    <IonInput readonly={true}>{learningItem.curriculumArea}</IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel>Subject:</IonLabel>
                    <IonInput readonly={true}>{learningItem.curriculumSubject}</IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel>Description: </IonLabel>
                    <IonTextarea readonly={true}>{learningItem.curriculumDesc}</IonTextarea>
                </IonItem>
                <IonItem>
                    <IonLabel>Student:</IonLabel>
                    <IonLabel>{learningItem.studentName}</IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>Teacher:</IonLabel>
                    <IonLabel>{learningItem.teacherName}</IonLabel>
                </IonItem>
                <IonItem>
                    <div dangerouslySetInnerHTML={{ __html: renderMarkdown(learningItem.notes) }} />
                </IonItem>
            </IonList>
        </IonContent>
        <IonFooter>
            <IonToolbar>
                <IonButtons slot="end">
                    <IonButton fill="solid" onClick={() => setShowDialog(false)}>OK</IonButton>
                </IonButtons>
            </IonToolbar>
        </IonFooter>
    </IonModal>
}

export default LearningInfoDialog;
