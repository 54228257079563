import "./SessionUtils";
import { generateHmac } from "./SessionUtils";
import { ServerHost } from '../Contract';
import React, { SetStateAction } from "react";

export function checkSession(callback : React.Dispatch<SetStateAction<boolean>>) {
    var email = getCredential("email");
    var session = getCredential("session");
    var secret = getCredential("secret");
    var role = getCredential("selectedRole");

    var timestamp = Date.now().toString();
    var hmac = generateHmac(secret, timestamp, session);

    var request = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            "id": Math.random().toString().substring(2, 4),
            "type": "rona.app.authentication.check",
            "body": {
                "email": email,
                "timestamp": timestamp,
                "secret": hmac,
                "role": +role,
            }
        })
    }

    fetch(ServerHost, request).then(async response => {
        var payload = await response.json();
        var body = payload.body;

        if (payload.type === "status" && body && body.code === 200) {
            callback(true);
        } else {
            callback(false);
        }
    }).catch((reason: any) => {
        callback(true);
    });
}

export async function exitSession() {
    var email = getCredential("email");
    var session = getCredential("session");
    var secret = getCredential("secret");
    var role = getCredential("selectedRole");

    var timestamp = Date.now().toString();
    var hmac = generateHmac(secret, timestamp, session);

    var request = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            "id": Math.random().toString().substring(2, 4),
            "type": "rona.app.authentication.logout",
            "body": {
                "email": email,
                "timestamp": timestamp,
                "secret": hmac,
                "role": +role,
            }
        })
    }

    await fetch(ServerHost, request);
}

function getCredential(key : string) : string {
    var value = localStorage.getItem(key);
    if (!value) {
        value = "";
    }
    return value;
}