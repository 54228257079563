import "./SessionUtils";
import { ServerHost, createRequestPost } from '../Contract';
import React, { SetStateAction } from "react";

export function retrieveCurriculums(setCurriculums : React.Dispatch<any>, loadingShow : React.Dispatch<SetStateAction<boolean>>) {
    var request = createRequestPost("rona.app.curriculums.get", {});

    fetch(ServerHost, request).then(async response => {
        if (response.ok) {
            var payload = await response.json();
            var body = payload.body;

            if (payload.type === "rona.app.curriculums.get" && body) {
                setCurriculums(JSON.parse(body));
            } else {
                console.log("Error in retrieving curriculums, received status: " + payload.type);
            }
        } else {
            console.log("failed to retrieve curriculums, received response: " + response.status);
        }
    }).catch((reason: any) => {
        console.log("Error to retrieve curriculums", reason);
    }).finally(() => {
        loadingShow(false);
    });
}

export function createNewCurriculum(callback : React.Dispatch<SetStateAction<boolean>>, name : string, type : number, parent? : number, desc? : string) {
    var request = createRequestPost("rona.app.curriculums.new", {
        "name": name,
        "type": type,
        "parent": parent,
        "description": desc
    });

    fetch(ServerHost, request).then(async response => {
        if (response.ok) {
            var payload = await response.json();
            var body = payload.body;

            if (payload.type === "status" && body && body.code === 200) {
                callback(true);
            } else {
                callback(false);
                console.log("Error in creating curriculums, received status: " + payload.type);
            }
        } else {
            callback(false);
            console.log("failed to create curriculums, received response: " + response.status);
        }
    }).catch((reason: any) => {
        callback(false);
        console.log("Error to create curriculums", reason);
    })
}

export function updateCurriculum(callback : React.Dispatch<SetStateAction<boolean>>, id : string, name : string, type : number, parent? : number, desc? : string) {
    var request = createRequestPost("rona.app.curriculums.update", {
        "id": id,
        "name": name,
        "type": type,
        "parent": parent,
        "description": desc
    });

    fetch(ServerHost, request).then(async response => {
        if (response.ok) {
            var payload = await response.json();
            var body = payload.body;

            if (payload.type === "status" && body && body.code === 200) {
                callback(true);
            } else {
                callback(false);
                console.log("Error in updating curriculums, received status: " + payload.type);
            }
        } else {
            callback(false);
            console.log("failed to update curriculums, received response: " + response.status);
        }
    }).catch((reason: any) => {
        callback(false);
        console.log("Error to update curriculums", reason);
    })
}

export function deleteCurriculum(callback : React.Dispatch<SetStateAction<number>>, id : string) {
    var request = createRequestPost("rona.app.curriculums.delete", { "id": id });

    fetch(ServerHost, request).then(async response => {
        if (response.ok) {
            var payload = await response.json();
            var body = payload.body;

            if (payload.type === "status" && body && body.code === 200) {
                callback(1);
            } else {
                callback(-1);
                console.log("Error in deleting curriculums, received status: " + payload.type);
            }
        } else {
            callback(-2);
            console.log("failed to delete curriculums, received response: " + response.status);
        }
    }).catch((reason: any) => {
        callback(-3);
        console.log("Error to delete curriculums", reason);
    })
}
