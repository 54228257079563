import { generateHmac } from "../SessionUtils";
import { ServerHost, StateFail, StateInProgress, StateSuccess } from '../../Contract';
import { SetStateAction } from "react";

export function addParent(callback : React.Dispatch<SetStateAction<number>>, personEmail : string, personName : string, family? : string) {
    var email = getCredential("email");
    var role = getCredential("selectedRole");
    var session = getCredential("session");
    var secret = getCredential("secret");

    var timestamp = Date.now().toString();
    var hmac = generateHmac(secret, timestamp, session);

    addPerson(callback, {
        "id": Math.random().toString().substring(2, 4),
        "type": "rona.app.parent.new",
        "body": {
            "email": email,
            "role": +role,
            "timestamp": timestamp,
            "secret": hmac,
            "parentEmail": personEmail,
            "parentName": personName,
            "parentFamily": family
        }
    });
}

export function addTeacher(callback : React.Dispatch<SetStateAction<number>>, personEmail : string, personName : string) {
    var email = getCredential("email");
    var role = getCredential("selectedRole");
    var session = getCredential("session");
    var secret = getCredential("secret");

    var timestamp = Date.now().toString();
    var hmac = generateHmac(secret, timestamp, session);
    
    addPerson(callback, {
        "id": Math.random().toString().substring(2, 4),
        "type": "rona.app.teacher.new",
        "body": {
            "email": email,
            "role": +role,
            "timestamp": timestamp,
            "secret": hmac,
            "teacherEmail": personEmail,
            "teacherName": personName
        }
    });
}

export function addStudent(callback : React.Dispatch<SetStateAction<number>>, parentEmail : string, studentName : string, birthDate : string, gender : number) {
    var email = getCredential("email");
    var role = getCredential("selectedRole");
    var session = getCredential("session");
    var secret = getCredential("secret");

    var timestamp = Date.now().toString();
    var hmac = generateHmac(secret, timestamp, session);

    addPerson(callback, {
        "id": Math.random().toString().substring(2, 4),
        "type": "rona.app.student.new",
        "body": {
            "email": email,
            "role": +role,
            "timestamp": timestamp,
            "secret": hmac,
            "parent": parentEmail,
            "studentName": studentName,
            "birthdate": birthDate,
            "gender": +gender
        }
    });
}

function addPerson(callback : React.Dispatch<SetStateAction<number>>, requestBody : Object) {
    var request = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody)
    }

    callback(StateInProgress);
    fetch(ServerHost, request).then(async response => {
        if (response.ok) {
            var payload = await response.json();
            var body = payload.body;

            if (payload.type === "status" && body && body.code === 200) {
                console.log("Success saving person: " + body);
                callback(StateSuccess);
            } else {
                console.log("Error in saving person, received status: " + body.code);
                callback(StateFail);
            }
        } else {
            console.log("failed to saving person, received response: " + response.status);
            callback(StateFail);
        }
    }).catch((reason: any) => {
        console.log("Error to save person", reason);
        callback(StateFail);
    })
}

function getCredential(key : string) : string {
    var value = localStorage.getItem(key);
    if (!value) {
        value = "";
    }
    return value;
}