import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonInput, IonItem, IonLabel, IonList, IonModal, IonText, IonTextarea, IonTitle, IonToolbar } from "@ionic/react"
import { SetStateAction, useEffect, useState } from "react"
import { renderMarkdown, updateLearning } from "./Learnings"
import { StateFail, StateInit, StateSuccess } from "../../Contract"
import { uploadFile } from "./FileUpload"

interface LearningUpdateDialogProps {
    learningItem: any
    isShowDialog: boolean
    setShowDialog: React.Dispatch<SetStateAction<boolean>>
}

const LearningUpdateDialog: React.FC<LearningUpdateDialogProps> = ({ learningItem, isShowDialog, setShowDialog}) => {
    const [txProgress, setTxProgress] = useState<string>(learningItem.notes);
    const [stUpdate, setStUpdate] = useState<number>(StateInit);
    var imageFile : File | undefined;

    function onUpdateClicked() {
        setStUpdate(StateInit);
        if (imageFile) {
            uploadFile(learningItem.studentId + "_" + learningItem.id, imageFile).then(result => {
                if (result) {
                    const image = JSON.parse(result);
                    const valProgress = ((txProgress && learningItem.notes !== txProgress) ? (appendWithImageToProgressHistory(txProgress, image)) : undefined);
                    updateLearning(learningItem.id, setStUpdate, undefined, undefined, undefined, valProgress);
                } else {
                    setStUpdate(StateFail);
                }
            });
        } else {
            const valProgress = ((txProgress && learningItem.notes !== txProgress) ? (appendProgressHistory(txProgress)) : undefined);
            updateLearning(learningItem.id, setStUpdate, undefined, undefined, undefined, valProgress);
        }
    }

    function appendProgressHistory(progress : string) : string {
        if (learningItem.notes) {
            return progress + "\n\n" + learningItem.notes;
        } else {
            return progress;
        }
    }

    function appendWithImageToProgressHistory(progress : string, image : any) : string {
        return appendProgressHistory(progress
            + "\n\n[![image](" + image.imagePreviewUrl
            + ")](" + image.imageUrl + ")");
    }

    function onImageFileChange(list : FileList | null) {
        if (list && list.length > 0) {
            imageFile = list[0];
        } else {
            imageFile = undefined;
        }
    }

    useEffect(() => {
        if (stUpdate === StateSuccess) {
            learningItem.notes = appendProgressHistory;
            setShowDialog(false);
        }
    }, [stUpdate]);

    useEffect(() => {
        setTxProgress("");
    }, [isShowDialog])

    
    return <IonModal isOpen={isShowDialog} onDidDismiss={() => { setShowDialog(false) }}>
         <IonHeader>
            <IonToolbar>
                <IonTitle>{learningItem.curriculumName}</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <IonList>
                <IonItem>
                    <IonLabel>Area:</IonLabel>
                    <IonInput readonly={true}>{learningItem.curriculumArea}</IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel>Subject:</IonLabel>
                    <IonInput readonly={true}>{learningItem.curriculumSubject}</IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel>Description: </IonLabel>
                    <IonTextarea readonly={true}>{learningItem.curriculumDesc}</IonTextarea>
                </IonItem>
                <IonItem>
                    <IonLabel>Student:</IonLabel>
                    <IonLabel>{learningItem.studentName}</IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>Teacher:</IonLabel>
                    <IonLabel>{learningItem.teacherName}</IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>Progress:</IonLabel>
                    <IonTextarea
                        readonly={false} autoGrow={true}
                        placeholder={'31/12/2024\nWhat, how, and extra notes'}
                        value={txProgress} onIonChange={ e => setTxProgress(e.detail.value!)}>
                    </IonTextarea>
                    <input type="file" name="file" id="file" onChange={e => onImageFileChange(e.target.files) } accept="image/jpg,image/jpeg" />
                </IonItem>
                <IonItem>
                    <div dangerouslySetInnerHTML={{ __html: renderMarkdown(learningItem.notes) }} />
                </IonItem>
            </IonList>
        </IonContent>
        <IonFooter>
            <IonToolbar>
                <IonButtons slot="end">
                    <IonButton fill="solid" onClick={() => onUpdateClicked()}>Update</IonButton>
                    <IonButton fill="solid" onClick={() => setShowDialog(false)}>Cancel</IonButton>
                </IonButtons>
            </IonToolbar>
        </IonFooter>
    </IonModal>
}

export default LearningUpdateDialog;
