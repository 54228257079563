import { IonAvatar, IonCol, IonGrid, IonItem, IonLabel, IonRow } from "@ionic/react";
import './Peoples.css';

const Peoples: React.FC = () => {
    return <>
        <IonGrid>
            <IonRow className="ion-align-items-center">
                <IonCol size="4" className="ion-align-self-center">
                    <IonItem routerLink="/page/Students">
                        <IonAvatar slot="start">
                            <img src="/assets/icon/student.png" alt="Students"/>
                        </IonAvatar>
                        <IonLabel>Students</IonLabel>
                    </IonItem>
                </IonCol>
                <IonCol size="4" className="ion-align-self-center">
                    <IonItem routerLink="/page/Parents">
                        <IonAvatar slot="start">
                            <img src="/assets/icon/parent.png" alt="Parents"/>
                        </IonAvatar>
                        <IonLabel>Parents</IonLabel>
                    </IonItem>
                </IonCol>
                <IonCol size="4" className="ion-align-self-center">
                    <IonItem routerLink="/page/Teachers">
                        <IonAvatar slot="start">
                            <img src="/assets/icon/teacher.png" alt="Teachers" />
                        </IonAvatar>
                        <IonLabel>Teachers</IonLabel>
                    </IonItem>
                </IonCol>
            </IonRow>
        </IonGrid>
    </>
}

export default Peoples;