import { SetStateAction } from 'react';
import { getRoleName, RoleCodeTeacher } from '../Contract';
import Curriculums from '../pages/Curriculums';
import Homepage from '../pages/Homepage';
import Parents from '../pages/peoples/Parents';
import Peoples from '../pages/peoples/Peoples';
import Students from '../pages/peoples/Students';
import Teachers from '../pages/peoples/Teachers';
import './ExploreContainer.css';
import StudentDetailPage from '../pages/peoples/StudentDetail';
import ParentDetailPage from '../pages/peoples/ParentDetail';

interface ContainerProps {
  name: string
  loadingMessage : React.Dispatch<SetStateAction<string>>
  loadingShow : React.Dispatch<SetStateAction<boolean>>
  toastMessage : React.Dispatch<SetStateAction<string>>
  toastShow : React.Dispatch<SetStateAction<boolean>>
}

const ExploreContainer: React.FC<ContainerProps> = ({ name, loadingMessage, loadingShow, toastMessage, toastShow }) => {
  function isManager() : boolean {
    var role = localStorage.getItem("selectedRole");
    var manage = false;
    if (role && RoleCodeTeacher === +role) {
      manage = true;
    }
    return manage;
  }

  if (name === "Home") {
    return <Homepage />
  } else if (name === "Curriculums") {
    return <Curriculums loadingMessage={loadingMessage} loadingShow={loadingShow} toastMessage={toastMessage} toastShow={toastShow} />
  } else if (name === "Peoples") {
    return <Peoples />
  } else if (name === "Teachers") {
    return <Teachers loadingMessage={loadingMessage} loadingShow={loadingShow} toastMessage={toastMessage} toastShow={toastShow}/>
  } else if (name === "Parents") {
    return <Parents loadingMessage={loadingMessage} loadingShow={loadingShow} toastMessage={toastMessage} toastShow={toastShow}/>
  } else if (name === "Students") {
    return <Students loadingMessage={loadingMessage} loadingShow={loadingShow} toastMessage={toastMessage} toastShow={toastShow} isManager={ isManager()} />
  } else if (name === "Student") {
    return <StudentDetailPage loadingMessage={loadingMessage} loadingShow={loadingShow} toastMessage={toastMessage} toastShow={toastShow} />
  } else if (name === "Parent") {
    return <ParentDetailPage loadingMessage={loadingMessage} loadingShow={loadingShow} toastMessage={toastMessage} toastShow={toastShow} isManager={isManager()} />
  } else {
    return (
      <div className="container">
        <strong>{name}</strong>
        <p>Explore <a target="_blank" rel="noopener noreferrer" href="https://ionicframework.com/docs/components">UI Components</a></p>
      </div>
    );
  }
};

export default ExploreContainer;
