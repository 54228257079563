import { SetStateAction } from "react";
import { ServerHost, StateFail, StateInProgress, StateSuccess, createRequestPost, isObjectEmpty } from "../../Contract";
import { marked } from "marked"

export function searchLearnings(student : number, setLearnings : React.Dispatch<any>, loadingShow : React.Dispatch<SetStateAction<boolean>>) {
    var request = createRequestPost("rona.app.learnings.search", { "student": student });
    loadingShow(true);

    fetch(ServerHost, request).then(async response => {
        if (response.ok) {
            var payload = await response.json();
            var body = payload.body;

            if (payload.type === "rona.app.learnings.search" && body) {
                console.log("retrieve learnings success", body);
                setLearnings(JSON.parse(body));
            } else {
                console.log("Error in retrieving learnings, received status: " + payload.type);
            }
        } else {
            console.log("failed to retrieve learnings, received response: " + response.status);
        }
    }).catch((reason: any) => {
        console.log("Error to retrieve learnings", reason);
    }).finally(() => {
        loadingShow(false);
    });
}

export function assignLearning(student : number, curriculum : number, date : string, teacher : string, callback : React.Dispatch<SetStateAction<boolean>>) {
    var request = createRequestPost("rona.app.learnings.assign", {
        "student": student,
        "curriculum": curriculum,
        "date": date,
        "teacher": teacher
    });

    fetch(ServerHost, request).then(async response => {
        if (response.ok) {
            var payload = await response.json();
            var body = payload.body;

            if (payload.type === "status" && body && body.code === 200) {
                callback(true);
            } else {
                console.log("Error in assigning learning, received status: " + body.code);
                callback(false);
            }
        } else {
            console.log("failed to assigning learnings, received response: " + response.status);
            callback(false);
        }
    }).catch((reason: any) => {
        console.log("Error to assigning learnings", reason);
        callback(false);
    });
}

export function updateLearning(id: string, callback : React.Dispatch<SetStateAction<number>>, date?: string, teacher?: string, score?: number, notes?: string) {
    var payload = { };
    if (date) {
        Object.assign(payload, { "time": date });
    }

    if (teacher) {
        Object.assign(payload, { "teacher": teacher });
    }

    if (score && score > 0) {
        Object.assign(payload, { "score": score });
    }

    if (notes) {
        Object.assign(payload, { "notes": notes });
    }

    if (!isObjectEmpty(payload)) {
        Object.assign(payload, { "id" : id });
    }

    var request = createRequestPost("rona.app.learnings.update", payload);
    callback(StateInProgress);

    fetch(ServerHost, request).then(async response => {
        if (response.ok) {
            var payload = await response.json();
            var body = payload.body;

            if (payload.type === "status" && body && body.code === 200) {
                console.log("Success update learning: " + body);
                callback(StateSuccess);
            } else {
                console.log("Error in update learning, received status: " + body.code);
                callback(StateFail);
            }
        } else {
            console.log("failed to assigning learnings, received response: " + response.status);
            callback(StateFail);
        }
    }).catch((reason: any) => {
        console.log("Error to assigning learnings", reason);
        callback(StateFail);
    });
}

export function renderMarkdown(text : string) : string {
    if (text) {
        return marked.parse(text.toString(), { async: false });
    } else {
        return "";
    }
}